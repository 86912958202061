import './bootstrap';
import './chart-custom';
import Swal from "sweetalert2"
import './input-mask';
import './main';
import './lightbox'

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

/*

document.addEventListener('DOMContentLoaded', function () {
    const dropzoneEl = document.getElementById('myDropzone');
    if (!dropzoneEl) return;

    var myDropzone = new Dropzone("#myDropzone", {
        autoProcessQueue: false,
        uploadMultiple: true,
        parallelUploads: 10,
        maxFilesize: 2, // MB
        acceptedFiles: 'image/*,video/*',
        addRemoveLinks: true,
        init: function () {
            var myDropzone = this;

            // Manually trigger file input
            document.getElementById('files').addEventListener('change', function () {
                Array.from(this.files).forEach(function (file) {
                    myDropzone.addFile(file);
                });
            });

            // Handle form submit
            document.getElementById('submit-all').addEventListener('click', function () {
                myDropzone.processQueue();
            });

            // Add thumbnails
            this.on('thumbnail', function (file) {
                var thumbContainer = document.getElementById('thumb-container');
                var thumb = document.createElement('div');
                thumb.className = 'thumb';
                thumb.id = `thumb-${file.upload.uuid}`;
                thumb.innerHTML = `
                    <img src="${file.dataURL}" alt="${file.name}">
                    <button class="remove-btn" onclick="removeFile('${file.upload.uuid}')">x</button>
                `;
                thumbContainer.appendChild(thumb);
            });

            this.on('sending', function (file, xhr, formData) {
                // Ek form verilerini ekleyin
                formData.append('_token', document.querySelector('input[name="_token"]').value);
                formData.append('privateKey', 'formprivatekey!'); // Örnek private key
            });

            this.on('success', function (file, response) {
                console.log(response.success);
            });

            this.on('error', function (file, response) {
                console.error(response.error);
            });

            this.on('removedfile', function (file) {
                document.getElementById(`thumb-${file.upload.uuid}`).remove();
            });
        }
    });

    window.removeFile = function (uuid) {
        var file = myDropzone.getAcceptedFiles().find(file => file.upload.uuid === uuid);
        myDropzone.removeFile(file);
    }
});

 */


const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

(function (jQuery) {
    jQuery('.logout-btn').on('click', function (e) {
        e.preventDefault();
        jQuery('#logout-form').submit();
    });
})(jQuery);
